<template>
    <Layout pagetitle="Relatórios">
        <b-tabs
            v-model="tabIndex"
            content-class="mt-3"
            small
            fill
        >
            <b-tab title="Pedidos Por Sorteio" active>
                <OrdersByRaffleReport v-if="tabIndex == 0" />
            </b-tab>
            <b-tab title="Números Por Cliente">
                <NumbersPerCustomerReport v-if="tabIndex == 1" />
            </b-tab>
        </b-tabs>
    </Layout>
</template>
<script>
import Layout from '@/views/layouts/main';
import OrdersByRaffleReport from '../../../components/reports/OrdersByRaffleReport.vue'
import NumbersPerCustomerReport from '../../../components/reports/NumbersPerCustomerReport.vue'
import { ref } from 'vue';

export default {
    components: {
        Layout,
        OrdersByRaffleReport,
        NumbersPerCustomerReport
    },
    setup() {
        const tabIndex = ref(0)

        return {
            tabIndex
        }
    }
}
</script>
