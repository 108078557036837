<template>
  <v-select
    class="custom-select"
    :options="options"
    @open="fetchOptions"
    @search="fetchOptions"
  >
  </v-select>
</template>

<script>
import { ref } from 'vue';
import debounce from '@/utils/debounce';
import { axios } from '@/axios';

export default {
  setup() {
    const options = ref([])

    const fetchApi = (params = {}) => {
      params.paginate = false
      params.select = 'id,title'
      return axios.get('/seller/contests', { params }).then(({ data }) => data)
    }

    const fetchOptions = debounce((search = '') => {
      fetchApi({ title: search, order: { id: 'desc' } })
        .then((data) => {
          options.value = data.map(({ id, title }) => ({
            label: title,
            value: id
          }))
      })
    }, 500)

    return {
      options,
      fetchOptions
    }
  },
}
</script>
