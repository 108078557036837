import fileDownload from 'js-file-download'
import { axios } from '../../axios'

export const getOrdersByRaffle = (params = {}) => {
    return axios.get('/report/orders-by-raffle', { params }).then(({ data }) => data)
}

export const exportOrdersByRaffle = async (params = {}) => {
    const fileName = `lista_pedidos.csv`

    const response = await axios.get('/report/export-orders-by-raffle', { params }, { responseType: 'blob' })

    const fileNameHeader = "x-suggested-filename"
    const suggestedFileName = response.headers[fileNameHeader]
    const effectiveFileName = suggestedFileName || fileName
    fileDownload(response.data, effectiveFileName)
}

export const getNumbersPerCustomer = (params = {}) => {
    return axios.get('/report/numbers-per-order', { params }).then(({ data }) => data)
}

export const exportNumbersPerCustomer = async (params = {}) => {
    const fileName = `lista_numeros.csv`

    const response = await axios.get('/report/export-numbers-per-order', { params }, { responseType: 'blob' })

    const fileNameHeader = "x-suggested-filename"
    const suggestedFileName = response.headers[fileNameHeader]
    const effectiveFileName = suggestedFileName || fileName
    fileDownload(response.data, effectiveFileName)
}

export const getCustomers = (params = {}) => {
    return axios.get('/customers', { params }).then(({ data }) => data)
}