<template>
    <b-card no-body>
        <b-card-body>
            <b-card-title>
                <small class="text-dark">
                    Serão levados em consideração apenas números já pagos.
                </small>
            </b-card-title>
            <b-form class="mt-3">
                <b-row class="g-3">
                    <b-col md="4">
                        <label>Período</label>
                        <VueDatePicker
                            v-model="period"
                            range
                            auto-apply
                            clearable
                            close-on-scroll
                            format="dd/MM/yyyy"
                            :enable-time-picker="false"
                            locale="pt"
                            model-type="yyyy-MM-dd"
                            dark
                        />
                    </b-col>
                    <b-col md="4">
                        <label>Sorteio</label>
                        <SelectContest 
                            placeholder="Pesquisar por sorteio"
                            v-model="contestId"
                        />
                    </b-col>
                    <b-col md="4">
                        <label>Cliente</label>
                        <v-select
                            class="custom-select"
                            placeholder="Pesquisar por cliente"
                            v-model="customerId"                          
                            :options="customerOptions"
                            @search="fetchCustomers"
                        />
                    </b-col> 
                </b-row>
            </b-form>
        </b-card-body>
        <template #footer>
            <em>
                <b-col class="text-end">
                    <b-button
                        variant="primary"
                        size="sm"
                        :disabled="loading"
                        @click="fetchNumbersPerCustomer"
                    >
                        <b-icon icon="search"></b-icon>
                        Buscar
                    </b-button>
                </b-col>
            </em>
        </template>
    </b-card>
    <b-card no-body>
        <b-card-body>
            <b-row>
                <b-col>
                    <h5>
                        <b-badge variant="primary">Resultados: {{ pagination.total_rows }}</b-badge>
                    </h5>
                </b-col>
                <b-col class="text-end">
                    <b-overlay
                        :show="exportingResults"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="info"
                        class="d-inline-block"
                        >
                        <b-button
                            variant="info"
                            @click="exportResults"
                            :disabled="exportingResults || loading"
                            size="sm"
                        >
                            <b-icon icon="cloud-upload"></b-icon>
                            Exportar
                        </b-button>
                    </b-overlay>
                </b-col>
            </b-row>
            <b-row v-if="items.length && !loading">
                <b-table
                    :items="items"
                    :fields="columns"
                    id="my-table"
                    responsive="sm"
                    hover
                >
                    <template #cell(whatsapp)="row">
                        {{ maskPhone(row.item.whatsapp) }}
                    </template>
                </b-table>
                <b-pagination
                    v-model="pagination.page"
                    :total-rows="pagination.total_rows"
                    :per-page="pagination.per_page"
                    :disabled="loading"
                    class="mt-2"
                    aria-controls="my-table"
                    align="center"
                    size="sm"
                />
            </b-row>
            <b-row v-else class="text-center mt-2">
                <div v-if="loading" class="text-center my-2">
                    <b-spinner class="align-middle" />
                </div>
                <b-alert v-else show variant="warning" class="text-center"><b>Sem Resultados</b></b-alert>
            </b-row>
        </b-card-body>
    </b-card>
</template>
<script>
import { reactive, ref, watch } from 'vue';
import { getNumbersPerCustomer, exportNumbersPerCustomer, getCustomers } from '../../services/reports/api'
import { Toasts } from '../../utils/toast-alerts'
import { moneyFormat } from "../../utils/formatter";
import SelectContest from '@/components/select-contest'
import dayjs from 'dayjs'
import debounce from '@/utils/debounce'

export default {
    components: {
        SelectContest,
    },
    setup() {
        const items = ref([])
        const pagination = reactive({
            total_rows: 0,
            per_page: 10,
            page: 1
        })
        const contestId = ref(null)
        const customerId = ref(null)
        const customerOptions = ref([])
        const now = dayjs().format('YYYY-MM-DD')
        const period = ref([now, now])

        const loading = ref(false)
        const exportingResults = ref(false)
        const columns = ref([
            {
                key: 'whatsapp',
                label: 'Whatsapp',
            },
            {
                key: 'name',
                label: 'Nome',
            },
            {
                key: 'number',
                label: 'Número'
            }
        ])

        const fetchCustomers = debounce((search = '') => {
            getCustomers({ name: search }).then((data) => {
                customerOptions.value = data.data.map(({ id, name }) => ({
                    label: name,
                    value: id
                }))
            })
        }, 500)

        const fetchNumbersPerCustomer = () => {
            loading.value = true
            let perioder = []
            let contest = null
            let customer = null
            
            if (period.value) {
                perioder.push(period.value[0])
                perioder.push(dayjs(period.value[1]).add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm'))
            }

            if (contestId.value) {
                contest = contestId.value['value']
            }

            if (customerId.value) {
                customer = customerId.value['value']
            }

            const params = {
                ...pagination,
                contest: contest,
                period: perioder,
                customer: customer
            }

            getNumbersPerCustomer(params).then((data) => {
                items.value = data.data
                pagination.total_rows = data.total
                loading.value = false
            }).catch(() => {
                Toasts('error', 'Erro ao buscar Números por Cliente')
                loading.value = false;
            }).finally(() => {
                loading.value = false;
            })
        }

        const exportResults = () => {
            exportingResults.value = true;
            let perioder = []
            let contest = null
            
            if (period.value) {
                perioder.push(period.value[0])
                perioder.push(dayjs(period.value[1]).add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm'))
            }

            if (contestId.value) {
                contest = contestId.value['value']
            }

            const params = {
                ...pagination,
                contest: contest,
                period: perioder,
                is_export: true,
            }

            exportNumbersPerCustomer(params).then(() => {
                exportingResults.value = false

                Toasts('success', 'Números por Clientes Exportados com Sucesso')
            }).catch(() => {
                Toasts('error', 'Erro ao buscar Números por Cliente')
                exportingResults.value = false;
            }).finally(() => {
                exportingResults.value = false;
            })
        }

        watch(pagination, fetchNumbersPerCustomer)

        return{
            items,
            pagination,
            columns,
            contestId,
            customerId,
            customerOptions,
            period,
            loading,
            fetchNumbersPerCustomer,
            moneyFormat,
            exportResults,
            exportingResults,
            fetchCustomers
        }
    },
};
</script>