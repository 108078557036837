<template>
  <b-card no-body>
    <b-card-body>
      <b-form class="mt-3">
        <b-row class="g-3">
          <b-col md="6">
            <label>Período</label>
            <VueDatePicker
              v-model="period"
              range
              auto-apply
              clearable
              close-on-scroll
              format="dd/MM/yyyy"
              :enable-time-picker="false"
              locale="pt"
              model-type="yyyy-MM-dd"
              dark
            />
          </b-col>
          <b-col md="6">
            <label>Status</label>
            <v-select
              class="custom-select"
              v-model="status"
              :options="statusOptions"
              multiple
            />
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
    <template #footer>
      <em>
        <b-col class="text-end">
          <b-button
            variant="primary"
            size="sm"
            :disabled="loading"
            @click="fetchOrdersByRaffles"
          >
            <b-icon icon="search"></b-icon>
            Buscar
          </b-button>
        </b-col>
      </em>
    </template>
  </b-card>
  <b-card no-body>
    <b-card-body>
      <b-col>
        <h5>
          <b-badge variant="primary"
            >Resultados: {{ pagination.total_rows }}</b-badge
          >
        </h5>
      </b-col>
      <b-row>
        <b-col class="accordion mb-1 text-end" role="tablist">
          <b-button
            variant="primary"
            size="sm"
            class="text-end"
            v-b-toggle.accordion-1
            @click="toggleChart"
          >
            <b-icon v-if="showChart" icon="arrow-up"></b-icon>
            <b-icon v-else icon="arrow-down"></b-icon>
            {{ showChart ? "Fechar Gráfico" : "Mostrar Gráfico" }}
          </b-button>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <apexchart
              v-if="isPageMounted && !loading"
              height="200px"
              type="bar"
              :options="chartOptions"
              :series="chartSeries"
            />
            <b-col v-else class="text-center my-2">
              <b-spinner class="align-middle" />
            </b-col>
          </b-collapse>
        </b-col>
      </b-row>
      <hr v-if="showChart" />
      <b-row class="mt-4">
        <b-col class="text-end">
          <b-overlay
            :show="exportingResults"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="info"
            class="d-inline-block"
          >
            <b-button
              variant="info"
              @click="exportResults"
              :disabled="exportingResults || loading"
              size="sm"
            >
              <b-icon icon="cloud-upload"></b-icon>
              Exportar
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row v-if="items.length && !loading">
        <b-table
          :items="items"
          :fields="columns"
          id="my-table"
          responsive="sm"
          hover
        >
          <template #cell(orders_amount)="row">
            {{ moneyFormat(row.item.orders_amount) }}
          </template>
        </b-table>
        <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.total_rows"
          :per-page="pagination.per_page"
          :disabled="loading"
          class="mt-2"
          aria-controls="my-table"
          align="center"
          size="sm"
        />
      </b-row>
      <b-row v-else class="text-center mt-2">
        <div v-if="loading" class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
        <b-alert v-else show variant="warning" class="text-center"
          ><b>Sem Resultados</b></b-alert
        >
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import i18n from "@/i18n";
import { onMounted, reactive, ref, watch } from "vue";
import {
  getOrdersByRaffle,
  exportOrdersByRaffle,
} from "../../services/reports/api";
import dayjs from "dayjs";
import { Toasts } from "../../utils/toast-alerts";
import { moneyFormat } from "../../utils/formatter";

const defaultOverviewChartOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: 6,
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#fff", "#fff"],
  xaxis: {
    categories: [],
  },
  yaxis: {
    labels: {
      formatter: function (value, { seriesIndex }) {
        if (seriesIndex === 1) {
          return i18n.global.n(value, "currency");
        }
        return value;
      },
    },
    tickAmount: 4,
  },
  legend: {
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 1,
      inverseColors: true,
      gradientToColors: ["#f1f3f7", "#3b76e1"],
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 38, 100, 38],
    },
  },
};

export default {
  setup() {
    const items = ref([]);
    const pagination = reactive({
      total_rows: 0,
      per_page: 10,
      page: 1,
    });

    const showChart = ref(false);
    const loading = ref(false);
    const exportingResults = ref(false);
    const now = dayjs().format("YYYY-MM-DD");
    const period = ref([now, now]);
    const status = ref(null);
    const isPageMounted = ref(false);
    const columns = ref([
      {
        key: "contest_id",
        label: "ID Sorteio",
      },
      {
        key: "contest_title",
        label: "Sorteio",
      },
      {
        key: "orders_quantity",
        label: "Quant Pedidos",
      },
      {
        key: "orders_amount",
        label: "Total",
      },
    ]);
    const statusOptions = ref([
      {
        value: "CONFIRMED",
        label: "Confirmado",
      },
      {
        value: "PENDING",
        label: "Pendente",
      },
      {
        value: "PROCESSING",
        label: "Processando",
      },
      {
        value: "CANCELED",
        label: "Cancelado",
      },
    ]);

    const chartOptions = ref(defaultOverviewChartOptions);
    const chartSeries = ref([]);

    onMounted(() => {
      fetchOrdersByRaffles();
      isPageMounted.value = true;
    });

    const fetchOrdersByRaffles = () => {
      loading.value = true;
      let perioder = [];
      let statuses = [];

      if (period.value) {
        perioder.push(period.value[0]);
        perioder.push(
          dayjs(period.value[1])
            .add(23, "hour")
            .add(59, "minute")
            .format("YYYY-MM-DD HH:mm")
        );
      }

      if (status.value) {
        statuses = status.value.map(function (item) {
          return item["value"];
        });
      }

      const params = {
        ...pagination,
        status: statuses,
        period: perioder,
      };

      getOrdersByRaffle(params)
        .then((data) => {
          items.value = data.data;
          pagination.total_rows = data.total;
          loading.value = false;

          passDataToGraphic(data.data);
        })
        .catch(() => {
          Toasts("error", "Erro ao buscar Pedidos por Sorteio");
          loading.value = false;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const exportResults = () => {
      exportingResults.value = true;
      let perioder = [];
      let statuses = [];

      if (period.value) {
        perioder.push(period.value[0]);
        perioder.push(
          dayjs(period.value[1])
            .add(23, "hour")
            .add(59, "minute")
            .format("YYYY-MM-DD HH:mm")
        );
      }

      if (status.value) {
        statuses = status.value.map(function (item) {
          return item["value"];
        });
      }

      const params = {
        ...pagination,
        status: statuses,
        period: perioder,
        is_export: true,
      };

      exportOrdersByRaffle(params)
        .then(() => {
          exportingResults.value = false;

          Toasts("success", "Pedidos por Sorteios Exportados com Sucesso");
        })
        .catch(() => {
          Toasts("error", "Erro ao buscar Pedidos por Sorteio");
          exportingResults.value = false;
        })
        .finally(() => {
          exportingResults.value = false;
        });
    };

    const passDataToGraphic = (data) => {
      const counts = data.map(({ orders_quantity }) => orders_quantity);
      const amounts = data.map(({ orders_amount }) =>
        parseFloat(orders_amount)
      );
      const categories = data.map(({ contest_title }) => contest_title);

      chartOptions.value = {
        ...defaultOverviewChartOptions,
        ...{
          xaxis: {
            categories,
          },
        },
      };

      chartSeries.value = [
        { name: "Pedidos", data: counts },
        { name: "Valor", data: amounts },
      ];
    };

    watch(pagination, fetchOrdersByRaffles);

    return {
      items,
      pagination,
      columns,
      period,
      loading,
      fetchOrdersByRaffles,
      moneyFormat,
      statusOptions,
      status,
      exportResults,
      exportingResults,
      chartOptions,
      defaultOverviewChartOptions,
      chartSeries,
      showChart,
      isPageMounted,
    };
  },
  methods: {
    toggleChart() {
      this.showChart = !this.showChart;
    },
  },
};
</script>